<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Manual payment individual"
      ref="pageForm"
      :items="items"
    >
    </PageHeader>
    <show
      ref="showDetails"
      :item="dataDetails"
      :items="betItems"
      :jackpots="jackpots"
      :result="roundResult"
      :plenoAmount="plenoAmount"
    ></show>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <Tooltip>
                <p>
                  En este apartado podrás realizar pagos que por algún error no
                  se haya efectuado.
                </p>
                <ol>
                  <li>Selecciona un operador.</li>
                  <li>Selecciona una ruleta (virtual).</li>
                  <li>Selecciona un jugador.</li>
                  <li>
                    Visualiza las ultimas apuestas realizadas por el jugador.
                    <ol>
                      <li>Puedes guiarte por el codigo de la ronda.</li>
                      <li>
                        En la columna de acreditación se expone si esa
                        transacción posee un credito.
                      </li>
                      <li>
                        Al ver el detalle de la transacción fijate bien si la
                        apuesta realizada corresponde con el numero ganador.
                      </li>
                      <li>Efectuar pago, si corresponde.</li>
                    </ol>
                  </li>
                </ol>
              </Tooltip>
            </div>
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.from') }}: </span>
                  <b-form-input
                    v-model="filterData.fromDate"
                    type="date"
                    class="form-control-sm"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.until') }}: </span>
                  <b-form-input
                    v-model="filterData.toDate"
                    type="date"
                    class="form-control-sm"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <div class="row mt-4">
              <!-- <div class="col-sm-12 col-md-4">
                <label class="d-inline-flex align-items-center w-100"> 
                  {{ $t('filter.client') }}:
                  <select 
                    v-model="filterData.clientId"
                    name="client"
                    class="form-select form-select-sm m-2 " 
                    placeholder="Seleccione.."
                  >
                    <option disabled selected>{{ $t('helpers.select') }}...</option>
                    <option v-for="(item, index) in clients" :key="index" :value="item._id">
                      {{item.name}}
                    </option>
                  </select>
                </label>
              </div> -->
              <div class="col-sm-12 col-md-6">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.operator') }}:
                  <select
                    v-model="filterData.operatorId"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                    @change="handleChange(filterData.operatorId)"
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option
                      v-for="(item, index) in operators"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-6" v-if="filterData.operatorId">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.roulette') }}:
                  <select
                    v-model="filterData.rouletteId"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                    @change="handleChange(filterData.rouletteId)"
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option
                      v-for="(item, index) in roulettes"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-6" v-if="filterData.rouletteId">
                <label class="d-inline-flex align-items-center w-100">
                  Nombre del jugador:
                  <vs-input
                    v-model="filterData.player"
                    class="shadow-lg"
                    placeholder="readyplayerone"
                  />
                </label>
              </div>
              <div class="col-sm-12 col-md-6" v-if="filterData.player">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.player') }}:
                  <select
                    v-model="filterData.playerId"
                    name="player"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                    @change="handleChange(filterData.playerId)"
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option
                      v-for="(item, index) in players"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.username }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="d-flex">
              <vs-button
                type="button"
                @click="filtrar()"
                :disabled="!Object.entries(filterData).length"
              >
                {{ $t('filter.filter') }}
              </vs-button>
              <vs-button type="button" success @click="cancelFilter()">
                {{ $t('filter.cancel') }}
              </vs-button>
            </div>
            <div class="row mt-2" v-if="tableData.length">
              <div class="col-sm-12 col-md-6 d-inline-flex align-items-center">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center m-0">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center m-2">
                    {{ $t('filter.search') }}:
                    <vs-input v-model="filter" type="search" primary></vs-input>
                  </label>
                </div>
              </div>
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0">
                <b-table
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  v-if="tableData.length"
                >
                  <template #cell(debit)="data">
                    {{ data.item.type === 'debit' ? data.item.amount : 0 }}
                  </template>
                  <template #cell(credit)="data">
                    {{ data.item.type === 'credit' ? data.item.amount : 0 }}
                  </template>
                  <template #cell(betDetails)="data">
                    <div>
                      <vs-button type="button" @click="openDetails(data.item)">
                        {{ $t('helpers.look') }}
                      </vs-button>
                    </div>
                  </template>
                  <!-- <template #cell(transactionHasCredit)="data">
                    {{
                      data.item.transaction.filter((transaction) => {
                        if (transaction.type === "credit") {
                          return transaction;
                        }
                      }).length
                      ? $t('individualPayment.hasCredit')
                      : $t('individualPayment.noCredit')
                    }}
                  </template> -->
                  <template #cell(createdAt)="data">
                    <h5 class="font-size-16">
                      <div
                        class="createdAt"
                        @mouseover="handleOver"
                        @mouseleave="handleLeave"
                      >
                        {{
                          moment
                            .utc(data.item.createdAt)
                            .format('DD/MM/YYYY | HH:mm:ss')
                        }}
                        <span class="badge badge-time bg-primary rounded-pill"
                          >Server time:
                          {{
                            moment
                              .utc(data.item.createdAt)
                              .format('DD/MM/YYYY | HH:mm:ss')
                          }}</span
                        >
                      </div>
                    </h5>
                  </template>
                </b-table>
              </div>
            </b-overlay>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <button
                      class="btn btn-secondary mx-2 uil-angle-left"
                      @click="beforePage"
                      :disabled="!skip"
                    ></button>
                    <button
                      class="btn btn-primary mx-2 uil-angle-right"
                      @click="nextPage"
                      :disabled="tableData.length < limit"
                    ></button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main';
import PageHeader from '../../../components/page-header-simple.vue';
import { breadCrumbData } from '../../../helpers/breadcrumbs/breadCrumbs';
import Show from './show.vue';
import Tooltip from '../../../components/tooltip.vue';

export default {
  components: { PageHeader, Layout, Show, Tooltip },
  data() {
    return {
      tableData: [],
      items: breadCrumbData.individualPayment,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      skip: 0,
      limit: 10,
      currentPage: 1,
      title: this.$t('individualPayment.title'),
      loading: false,
      filterData: {},
      filterOn: ['roundCode', 'roundId'],
      filter: '',
      dataDetails: {},
      operators: [],
      roulettes: [],
      players: [],
      betItems: [],
      jackpots: [],
      plenoAmount: 0,
      roundResult: 0,
      fields: [
        {
          key: 'roundId',
          sortable: true,
          label: 'ROUND ID',
          class: 'space-nowrap align-middle',
        },
        {
          key: 'roundCode',
          sortable: true,
          label: this.$t('individualPayment.round').toUpperCase(),
          class: 'space-nowrap align-middle',
        },
        {
          key: 'player.username',
          sortable: false,
          label: this.$t('individualPayment.player').toUpperCase(),
          class: 'text-center align-middle space-nowrap',
        },
        {
          key: 'game.name',
          sortable: false,
          label: this.$t('individualPayment.roulette').toUpperCase(),
          class: 'text-center align-middle space-nowrap',
        },
        {
          key: 'type',
          sortable: false,
          label: this.$t('individualPayment.transactionType').toUpperCase(),
          class: 'align-middle space-nowrap',
        },
        {
          key: 'debit',
          sortable: false,
          label: this.$t('individualPayment.debit').toUpperCase(),
          class: 'align-middle space-nowrap',
        },
        {
          key: 'credit',
          sortable: false,
          label: this.$t('individualPayment.credit').toUpperCase(),
          class: 'align-middle space-nowrap',
        },
        {
          key: 'currency[0].short',
          sortable: false,
          label: this.$t('individualPayment.currency').toUpperCase(),
          class: 'align-middle space-nowrap',
        },
        {
          key: 'betDetails',
          sortable: false,
          label: this.$t('table.details').toUpperCase(),
          class: 'align-middle space-nowrap',
        },
        {
          key: 'createdAt',
          sortable: false,
          label: this.$t('table.data').toUpperCase(),
          class: 'align-middle space-nowrap',
        },
      ],
    };
  },
  methods: {
    getOperator() {
      this.$http
        .get('/operators')
        .then((response) => {
          this.operators = response.data.operators;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRoulettes(operatorId) {
      this.$http
        .get(`/operators/${operatorId}/roulettes`) /* /:operatorId/roullettes */
        .then(({ data }) => {
          const formatRoulette = data.operatorRoullettes.map((rou) => {
            return { name: rou.roulette.name, _id: rou.roulette._id };
          });

          this.roulettes = formatRoulette;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getOperatorPlayers() {
      try {
        const { data } = await this.$http.get(`player-round/find-by-term`, {
          params: {
            term: this.filterData.player,
          },
        });

        if (!data.ok) return (this.players = []);

        this.players = data.players;
      } catch (err) {
        console.log('ERROR FIND PLAYERS BY TERM', err);
      }
    },
    getRoundsBet() {
      this.loading = true;
      this.$http
        .get('player-round/findPlayerBets', {
          params: {
            operatorId: this.filterData.operatorId,
            playerId: this.filterData.playerId,
            rouletteId: this.filterData.rouletteId,
            limit: this.limit,
            page: this.currentPage,
            ...this.filterData,
          },
        })
        .then(({ data }) => {
          if (data.ok) {
            const formattedData = data.bets.map((bet) => {
              return {
                ...bet,
                roundCode: bet.round.code,
                roundId: bet.round.identifierNumber,
              };
            });
            this.tableData = formattedData;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log('Err', err);
          this.loading = true;
        });
    },
    async handleChange() {
      if (this.filterData.operatorId) {
        await this.getRoulettes(this.filterData.operatorId);
        // this.getOperatorPlayers(this.filterData.operatorId);
      }

      if (
        this.filterData.rouletteId &&
        this.filterData.operatorId &&
        this.filterData.playerId
      ) {
        await this.getRoundsBet();
      }
    },
    cancelFilter() {
      this.filterData = {};
    },
    openDetails(item) {
      if (!this.$refs.showDetails.modal) {
        this.betItems = [];
        this.jackpots = [];
        this.plenoAmount = 0;
      }

      this.jackpots = item.round.jackpot_values;
      this.dataDetails = item;
      this.plenoAmount = item.game.pleno;

      if (item.bet) {
        this.$http
          .get('/bets/details', {
            params: {
              _id: item.bet._id,
              type: item.type,
            },
          })

          .then(({ data }) => {
            this.bets = [];
            const betsFetch = data.bet ? data.bet.bet : [];

            const earnings = data.earnings;
            const earningsBets = earnings.map((earn) => {
              const winnerType = earn.bet.split('/')[0];
              const winnerNumber = earn.bet.split('/')[1];
              return {
                betType: winnerType,
                number: winnerNumber,
                betAmount: earn.amountOriginal,
                earnings: earn.earning,
              };
            });

            for (let bet in betsFetch) {
              if (betsFetch[bet].length) {
                if (bet === 'calleNumbers') {
                  const calleNumbers = [];

                  for (let item = 0; item < betsFetch[bet].length; item += 3) {
                    calleNumbers.push({
                      betType: bet,
                      amount: betsFetch[bet][item].amount,
                      number: [
                        betsFetch[bet][item].number,
                        betsFetch[bet][item].number + 1,
                        betsFetch[bet][item].number + 2,
                      ],
                    });
                  }

                  this.betItems.push(...calleNumbers);
                }

                if (bet === 'cuadroNumbers') {
                  const cuadroNumbers = [];

                  for (let item = 0; item < betsFetch[bet].length; item += 4) {
                    cuadroNumbers.push({
                      betType: bet,
                      amount: betsFetch[bet][item].amount,
                      number: [
                        betsFetch[bet][item].number,
                        betsFetch[bet][item].number + 1,
                        betsFetch[bet][item].number + 3,
                        betsFetch[bet][item].number + 4,
                      ],
                    });
                  }

                  this.betItems.push(...cuadroNumbers);
                }

                if (bet === 'lineaNumbers') {
                  const lineaNumbers = [];

                  for (let item = 0; item < betsFetch[bet].length; item += 6) {
                    if (betsFetch[bet][item].number === 0) {
                      lineaNumbers.push({
                        betType: bet,
                        amount: betsFetch[bet][item].amount,
                        number: [
                          betsFetch[bet][item].number,
                          betsFetch[bet][item].number + 1,
                          betsFetch[bet][item].number + 2,
                          betsFetch[bet][item].number + 3,
                        ],
                      });
                    } else {
                      lineaNumbers.push({
                        betType: bet,
                        amount: betsFetch[bet][item].amount,
                        number: [
                          betsFetch[bet][item].number,
                          betsFetch[bet][item].number + 1,
                          betsFetch[bet][item].number + 2,
                          betsFetch[bet][item].number + 3,
                          betsFetch[bet][item].number + 4,
                          betsFetch[bet][item].number + 5,
                        ],
                      });
                    }
                  }

                  this.betItems.push(...lineaNumbers);
                }

                if (bet === 'semiPlenoNumbers') {
                  const semiPlenoNumbers = [];
                  for (let item = 0; item < betsFetch[bet].length; item += 2) {
                    const number = betsFetch[bet][item].number;
                    const nextNumber = betsFetch[bet][item + 1].number;

                    semiPlenoNumbers.push({
                      betType: bet,
                      amount: betsFetch[bet][item].amount,
                      number: [number, nextNumber],
                    });
                  }

                  this.betItems.push(...semiPlenoNumbers);
                }

                if (bet === 'plenoNumbers') {
                  for (let item = 0; item < betsFetch[bet].length; item++) {
                    this.betItems.push({
                      betType: bet,
                      number: betsFetch[bet][item].number,
                      amount: betsFetch[bet][item].amount,
                    });
                  }
                }

                if (
                  [
                    'chanceSimple',
                    'dozens',
                    'columns',
                    'color',
                    'even_odd',
                    'cubre',
                  ].includes(bet)
                ) {
                  for (let item = 0; item < betsFetch[bet].length; item++) {
                    this.betItems.push({
                      betType: bet,
                      type: betsFetch[bet][item].type,
                      amount: betsFetch[bet][item].amount,
                    });
                  }
                }
              }
            }

            /* const betsWithEarnings =  */ this.betItems.map((bet, index) => {
              if (bet.type) {
                earningsBets.filter((earn) => {
                  if (
                    earn.betType === bet.betType &&
                    earn.number === bet.type &&
                    bet.amount === earn.betAmount
                  ) {
                    this.betItems[index] = {
                      ...this.betItems[index],
                      earnings: earn.earnings,
                    };
                    return earn.earnings;
                  }
                });
              } else {
                earningsBets.filter((earn) => {
                  if (
                    earn.betType === bet.betType &&
                    bet.amount === earn.betAmount
                  ) {
                    if (bet.number instanceof Array) {
                      if (bet.number.includes(Number(earn.number))) {
                        this.betItems[index] = {
                          ...this.betItems[index],
                          earnings: earn.earnings,
                        };
                      }
                    }

                    if (typeof bet.number === 'number') {
                      if (bet.number === Number(earn.number)) {
                        this.betItems[index] = {
                          ...this.betItems[index],
                          earnings: earn.earnings,
                        };
                      }
                    }
                    return earn.earnings;
                  }
                });
              }
            });

            this.roundResult = data.roundResult;
          })
          .catch((err) => {
            console.log(err);
          });
      }

      this.$refs.showDetails.modal = true;
    },
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'visible';
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'hidden';
      }
    },
    nextPage() {
      this.skip += this.perPage;
      this.currentPage++;
      this.getRoundsBet();
    },
    beforePage() {
      this.skip -= this.perPage;
      this.currentPage--;

      this.getRoundsBet();
    },
    filtrar() {
      this.getRoundsBet();
    },
  },
  mounted() {
    this.getOperator();
  },
  watch: {
    perPage() {
      this.skip = 0;
      this.limit = this.perPage;
      this.getRoundsBet();
    },
    async ['filterData.player']() {
      await this.getOperatorPlayers();
    },
  },
};
</script>

<style>
.createdAt {
  position: relative;
}

.badge-time {
  position: absolute;
  left: 0;
  top: -12px;
  visibility: hidden;
}

.space-nowrap {
  white-space: nowrap !important;
}
</style>
